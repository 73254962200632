.expert {
  padding-top: 1em;
  background-color: rgba(95, 158, 160, 0.034);
  height: 24em;
  border-radius: 0 0 2em 2em;
}

.expert p {
  padding-top: 1em;
  color: #37a99e;
}

.expert button {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 15em;
  cursor: pointer;
}

.expert button:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.expert button:focus {
  outline: none;
}


.expert
button:disabled,
button[disabled]{
  color: #555555;
  border: #555555 solid 0.05em;
}


.network p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}


.expert li {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.expert ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
