.Offer h1 {
  margin-top: 1.2em;
}
.icon {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.inputDiv {
  padding: 1em;
  height: 4em;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.input {
  height: 3em;
  width: 15em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

.select {
  width: 7em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
  color: #000000;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(23, 60, 139, 0.301);
}

.totals {
  margin-top: 3em;
  height: 3em;
}

.goMax {
  cursor: pointer;
}

.stakeButton {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 10em;
  margin-top: 1em;
  cursor: pointer;
}


.stakeButton:hover {
  background-color: #2675cf;
}

.stakeButton:focus {
  outline: none;
 }

.unstakeButton {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 12em;
  margin-top: 1em;
  cursor: pointer;
}




.unstakeButton:hover {
  background-color: #1c375f;
}

.unstakeButton:focus {
 outline: none;
}

.stakeIcon {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.stakeButton p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}

.unstakeButton p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}


.box div {
  width: 280px;
  margin: auto;
}

.box p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.box a {
  color: rgb(119, 157, 201);
  text-decoration: none;
}




.Offer li {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.Offer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Picker input {
  color: white;
}
