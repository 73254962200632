* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

xbody {
  margin: 0;
  background: linear-gradient(#122747, #010203);
  color: white;

  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
}

body {
  margin: 0;
/*  background: linear-gradient(#122747, #010203);*/
  color: white;

/*
  background-repeat: no-repeat;
  background-attachment: fixed; */
  height: 100%;

  background-image: url("./assets/paperDamask.jpg");
  background-size: 200px;

}
