.Meta h1 {
  margin-top: 1.2em;
}
.icon {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.inputDiv {
  padding: 3em;
  align-items: center;
}

.input {
  height: 3em;
  width: 20em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(23, 60, 139, 0.301);
}

.totals {
  margin-top: 3em;
  height: 3em;
}

.goMax {
  cursor: pointer;
}

.stakeButton {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 10em;
  margin-top: 1em;
  cursor: pointer;
}

.stakeButton:hover {
  background-color: #2675cf;
}

.unstakeButton {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 8em;
  margin-top: 1em;
  cursor: pointer;
}

.stakeButton:focus {
  outline: none;
 }

.unstakeButton:hover {
  background-color: #1c375f;
}

.unstakeButton:focus {
 outline: none;
}

.stakeIcon {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.stakeButton p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}

.unstakeButton p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}
