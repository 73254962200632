.buy {
  padding-top: 1em;
  background-color: rgba(95, 158, 160, 0.034);
  height: 24em;
  border-radius: 0 0 2em 2em;
}

.greened {
  padding-top: 1em;
  color: #37a99e;
}


.action {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 15em;
  cursor: pointer;
}

.action:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.action:focus {
  outline: none;
}


.action:disabled{
  color: #555555;
  border: #555555 solid 0.05em;
}


.network p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}


.buy li {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.buy ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}



.inputDiv {
  padding: 3em 0 3em 0;
  height: 5em;
  align-items: center;
}

.input {
  height: 3em;
  width: 28em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(23, 60, 139, 0.301);
}


.rampButton {
  background-color: #21bf73;
  color: white;
  font-size: 1em;
  border: #21bf73 solid 0.05em;
  border-radius: 0.5em;
  height: 2.5em;
  width: 6em;
  margin-top: 1em;
  cursor: pointer;
}

.rampButton:hover {
  background-color: #10af63;
}


.rampButton:focus {
  outline: none;
 }

.rampButton p {
   display: inline;
   position: relative;
 }


 .fcctButton {
  background-color: #404342;
  color: white;
  font-size: 1em;
  border: #404342 solid 0.05em;
  border-radius: 0.5em;
  height: 2.5em;
  width: 6em;
  margin-top: 1em;
  cursor: pointer;
}

.fcctButton:hover {
  background-color: #404342;
}


.fcctButton:focus {
  outline: none;
 }

.fcctButton p {
   display: inline;
   position: relative;
 }

