.App_Grid__2licQ {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto auto auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;

}

@media only screen and (max-device-width: 480px) {
  .App_Grid__2licQ {
    display: block;
    font-size: 16px;
  }
}

@media only screen and (max-device-width: 400px) {
  .App_Grid__2licQ {
    display: block;
    font-size: 13px;
    font-weight: 300;
  }
}

.App_icon__3Edqa {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}



.App_Child__7bQa2 {
  min-height: 600px;
  max-width: 600px;
  min-width: 90%;
/*
  background: linear-gradient(
      16deg,
      rgb(11, 30, 56) 0%,
      rgb(10, 37, 73) 42%,
      rgb(8, 45, 95) 100%
    ),
    linear-gradient(to right, rgb(37, 62, 99), rgb(11, 31, 70));
*/

  background-repeat: no-repeat;
  background-origin: padding-box, border-box;
  border-radius: 0em;
  grid-column: 3;
  grid-row: 2;
  text-align: center;
  color: white;
  box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
}

@media only screen and (max-device-width: 400px) {
  .App_tab-list-item__rsZ3V {
    min-width: none;
  }
}


.App_curtain__1q9k9 {
  background-color: rgba(0, 0, 0, 0.466);
  position: static;
  height: 99em;
  width: auto;
  grid-column-start: 1;
  grid-column-end: 6;
  z-index: 2;
  -webkit-filter: blur(8px);
          filter: blur(8px);
  -webkit-backdrop-filter: blur(1.5em);
  backdrop-filter: blur(1.5em);
}


a {
  color: rgb(119, 157, 201);
  text-decoration: none;
}

.Navigation_navigation__234Ip {
  grid-column: 3;
  grid-row: 2;
  position: absolute;
  margin-top: -2em;
  margin-left: 2em;

}

.Navigation_buttonActive__8pc8o {
  padding: 0.4em;
  font-weight: 600;
  color: rgb(58, 58, 58);
  background-color: #ffffff;
  border: none;
  width: 13em;
  height: 2em;
  border-radius: 3em 3em 0 0;
  margin-right: -1.5em;
  border: 0.3em solid rgba(8, 67, 134, 0.199);
  border-bottom: none;
  z-index: 1;
  position: relative;
  box-shadow: -10px 0px 13px 1px #00000031, 10px -10px 13px 5px #0000001f,
    0px -30px 50px 13px rgba(0, 0, 0, 0);
  cursor: pointer;
}
.Navigation_buttonNonActive__3zoQN {
  padding: 0.5em;
  font-weight: 600;
  color: rgba(247, 247, 247, 0.726);
  background-color: #5781cfc0;
  border: none;
  width: 11.5em;
  height: 2em;
  border-radius: 3em 3em 0 0;
  margin-right: -1.5em;
  border: 0.2em solid rgba(37, 66, 99, 0.705);
  border-bottom: none;
  z-index: 0;
  position: relative;
  cursor: pointer;
}

.Navigation_buttonNonActive__3zoQN:hover {
  background-color: #5781cfd7;
  color: rgb(247, 247, 247);
}

.Navigation_buttonActive__8pc8o:focus {
  outline: none;
}
.Navigation_buttonNonActive__3zoQN:focus {
  outline: none;
}

.tab-list {
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  padding-left: 0;

  white-space: nowrap;


  width: 100%;
}

@media only screen and (max-device-width: 480px) {
  .tab-list {

    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    -webkit-overflow-scrolling: touch;

    position: fixed;
    bottom: 0;
    background-color: black;
    height: 55px;
    margin: 0px;
  }
}


/* Hide scrollbar for IE, Edge and Firefox */
.tab-list {
}


.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 1rem 0.5rem;

  vertical-align: middle;
}

@media only screen and (max-device-width: 480px) {
  .tab-list-item {
    height: 100%;
    font-weight: 600;
    font-size: 16px;

    vertical-align: middle;
  }
}


.tab-list-active {
  background-color: #ffffff22;
  border-width: 1px 1px 0 1px;
  xcolor: rgb(6, 151, 201);
  color: rgb(201 166 6);
}

.Profile_profile__1nPbI {
  padding-top: 1em;
  background-color: rgba(95, 158, 160, 0.034);
  border-radius: 0 0 2em 2em;
}

.Profile_profile__1nPbI p {
  padding-top: 0.5em;
  color: #37a99e;
}

.Profile_profile__1nPbI button {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 15em;
  cursor: pointer;
}

.Profile_profile__1nPbI button:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.Profile_profile__1nPbI button:focus {
  outline: none;
}



.Profile_importButton__3BpOI {
  background-color: rgba(255, 255, 255, 0.0);
  color: white;
  font-size: 0.6em;
  border: rgb(253, 85, 36) solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 5em;
  margin-top: 0em;
  margin-left: 1em;
  cursor: pointer;
}

.Profile_importButton__3BpOI:hover {
  background-color: rgba(255, 255, 255, 0.0);
}


.Profile_importButton__3BpOI:focus {
  outline: none;
 }

.Profile_importButton__3BpOI p {
   display: inline;
   position: relative;
 }




.Profile_profile__1nPbI li {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.Profile_profile__1nPbI ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.Profile_network__eUF_c div {
  max-width: 520px;
  margin: auto;
}

.Profile_network__eUF_c p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.Profile_network__eUF_c a {
  color: white;
  text-decoration: none;
}


.Profile_box__1k-gY div {
  width: 350px;
  margin: auto;
}

@media only screen and (max-device-width: 400px) {
  .Profile_box__1k-gY div {
    width: 300px;
    margin: auto;
  }
}

.Profile_box__1k-gY p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.Profile_box__1k-gY a {
  color: white;
  text-decoration: none;
}




.Profile_box__1k-gY container {
    position: relative;
}

.Profile_box__1k-gY overlay {
     position: absolute;
     botton: 20px;
}



.Profile_preview__1c9le {
    position: relative;
    padding: 1em;
}

.Profile_previewPrice__1FXPE {
  padding: 0.0em;
}

.Profile_previewItem__1IL8W {
   width: 150px;
}

@media only screen and (max-device-width: 400px) {
  .Profile_previewItem__1IL8W {
     width: 100px;
  }
}


.Profile_gridItem__1G9bk {
  padding: 1em;
}

.Profile_frostedGlassEffect__1BwvZ {
  box-shadow: 0 0 1rem 0 rgba(33, 31, 31, 0.2);
  background-color: rgba(200, 200, 255, .08);
	-webkit-backdrop-filter: blur(2px);
	        backdrop-filter: blur(2px);
  border-radius: 10px;
  xborder: rgba(200,200,255,0.5) solid 1px;
  border: rgba(255,246,200,0.5) solid 1px;
  position: relative;
  overflow: hidden;
}

.Profile_tbl__3mmr2 {
  
}



.tbl th:first-of-type {
  border-top-left-radius: 10px;
}
.tbl th:last-of-type {
  border-top-right-radius: 10px;
}

.tbl tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 10px;
}
.tbl tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 10px;
}

.tbl tr:first-of-type td:first-of-type {
  border-top-left-radius: 10px;
}
.tbl tr:first-of-type td:last-of-type {
  border-top-right-radius: 10px;
}

.tbl table {
    border-collapse:separate;
    border: rgba(255,246,200,0.5) solid 1px;
    xborder:solid rgba(200,200,255,0.5) 1px;
    width: 100%;
    border-radius:12px;
}
/*

.tbl td, th {
    border-left:solid rgba(200,200,255,0.5) 1px;
    border-top:solid rgba(200,200,255,0.5) 1px;
}

.tbl th {
    background-color: rgba(200,200,255,0.5) 1px;
    border-top: none;
}

.tbl td:first-child, th:first-child {
     border-left: none;
}

*/

.tbl td {
  color: rgb(119, 157, 201);
  ycolor: rgb(201 166 6);
  font-size: 0.8em;
  padding: 0.4em;
}

.tbl td:nth-child(2) {
  font-weight: 600;
  text-align: left;
}

.tbl td:nth-child(1) {
  text-align: right;
  background: rgba(119,157,201);
  xbackground: linear-gradient(to right, #FCF6BA, #AA771C);
  color: white;
  /*
  background-color:  rgb(119, 157, 201);
  color: rgba(200,200,255,0.5);
  */

}

.Meta_Meta__33Ls- h1 {
  margin-top: 1.2em;
}
.Meta_icon__2y7ew {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.Meta_inputDiv__1S57G {
  padding: 3em;
  align-items: center;
}

.Meta_input__3Enr5 {
  height: 3em;
  width: 20em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(23, 60, 139, 0.301);
}

.Meta_totals__2y-up {
  margin-top: 3em;
  height: 3em;
}

.Meta_goMax__3feMn {
  cursor: pointer;
}

.Meta_stakeButton__aOffX {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 10em;
  margin-top: 1em;
  cursor: pointer;
}

.Meta_stakeButton__aOffX:hover {
  background-color: #2675cf;
}

.Meta_unstakeButton__3LDP1 {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 8em;
  margin-top: 1em;
  cursor: pointer;
}

.Meta_stakeButton__aOffX:focus {
  outline: none;
 }

.Meta_unstakeButton__3LDP1:hover {
  background-color: #1c375f;
}

.Meta_unstakeButton__3LDP1:focus {
 outline: none;
}

.Meta_stakeIcon__2g_WJ {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.Meta_stakeButton__aOffX p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}

.Meta_unstakeButton__3LDP1 p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}

.InStore_inStore__6FjTT {
  padding-top: 1em;
  background-color: rgba(95, 158, 160, 0.034);
}

.InStore_inStore__6FjTT p {
  padding-top: 1em;
  color: #37a99e;
}

.InStore_inStore__6FjTT a {
  color: white;
}

.InStore_inStore__6FjTT button {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 10em;
  cursor: pointer;
}

.InStore_inStore__6FjTT button:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.InStore_inStore__6FjTT button:focus {
  outline: none;
}

.InStore_inStore__6FjTT
button:disabled,
button[disabled]{
  color: #555555;
  border: #555555 solid 0.05em;
}


.InStore_network__1bVN- p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}


.InStore_inStore__6FjTT li {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.InStore_inStore__6FjTT ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}



.InStore_stakeIcon__3txEH {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.InStore_inputDiv__27ZNm {
  padding: 3em 0 3em 0;
  height: 5em;
  align-items: center;
}

.InStore_input__hVVGn {
  height: 3em;
  width: 28em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(23, 60, 139, 0.301);
}



.InStore_stakeButton__3PxfT {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 20em;
  margin-top: 1em;
  cursor: pointer;
}


.InStore_stakeButton__3PxfT:hover {
  background-color: #2675cf;
}

.InStore_stakeButton__3PxfT:focus {
  outline: none;
 }

 .InStore_stakeButton__3PxfT p {
   display: inline;
   position: relative;
   bottom: 0.5em;
   color: white;
 }

.OutStore_outStore__1UXae {
  padding-top: 1em;
  background-color: rgba(95, 158, 160, 0.034);
}

.OutStore_outStore__1UXae p {
  padding-top: 1em;
  color: #37a99e;
}

.OutStore_outStore__1UXae a {
  color: white;
}

.OutStore_outStore__1UXae button {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 10em;
  cursor: pointer;
}

.OutStore_outStore__1UXae button:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.OutStore_outStore__1UXae button:focus {
  outline: none;
}


.OutStore_outStore__1UXae
button:disabled,
button[disabled]{
  color: #555555;
  border: #555555 solid 0.05em;
}


.OutStore_network__2TpFs p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}


.OutStore_outStore__1UXae li {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.OutStore_outStore__1UXae ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}






.OutStore_stakeIcon__KNdn2 {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.OutStore_inputDiv__13qKi {
  padding: 3em 0 3em 0;
  height: 5em;
  align-items: center;
}

.OutStore_input__2mAWM {
  height: 3em;
  width: 28em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(23, 60, 139, 0.301);
}



.OutStore_stakeButton__2cpia {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 20em;
  margin-top: 1em;
  cursor: pointer;
}


.OutStore_stakeButton__2cpia:hover {
  background-color: #2675cf;
}

.OutStore_stakeButton__2cpia:focus {
  outline: none;
 }

 .OutStore_stakeButton__2cpia p {
   display: inline;
   position: relative;
   bottom: 0.5em;
   color: white;
 }

.Expert_expert__TvSOp {
  padding-top: 1em;
  background-color: rgba(95, 158, 160, 0.034);
  height: 24em;
  border-radius: 0 0 2em 2em;
}

.Expert_expert__TvSOp p {
  padding-top: 1em;
  color: #37a99e;
}

.Expert_expert__TvSOp button {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 15em;
  cursor: pointer;
}

.Expert_expert__TvSOp button:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.Expert_expert__TvSOp button:focus {
  outline: none;
}


.Expert_expert__TvSOp
button:disabled,
button[disabled]{
  color: #555555;
  border: #555555 solid 0.05em;
}


.Expert_network__1zqWx p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}


.Expert_expert__TvSOp li {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.Expert_expert__TvSOp ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Offer_Offer__Fwt_G h1 {
  margin-top: 1.2em;
}
.Offer_icon__1ldeb {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.Offer_inputDiv__2t-Cz {
  padding: 1em;
  height: 4em;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.Offer_input__3a545 {
  height: 3em;
  width: 15em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

.Offer_select__2kyxu {
  width: 7em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
  color: #000000;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(23, 60, 139, 0.301);
}

.Offer_totals__2AST1 {
  margin-top: 3em;
  height: 3em;
}

.Offer_goMax__2yFXo {
  cursor: pointer;
}

.Offer_stakeButton__2XPur {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 10em;
  margin-top: 1em;
  cursor: pointer;
}


.Offer_stakeButton__2XPur:hover {
  background-color: #2675cf;
}

.Offer_stakeButton__2XPur:focus {
  outline: none;
 }

.Offer_unstakeButton__1cWa- {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 12em;
  margin-top: 1em;
  cursor: pointer;
}




.Offer_unstakeButton__1cWa-:hover {
  background-color: #1c375f;
}

.Offer_unstakeButton__1cWa-:focus {
 outline: none;
}

.Offer_stakeIcon__3xOYB {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.Offer_stakeButton__2XPur p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}

.Offer_unstakeButton__1cWa- p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}


.Offer_box__3I1lw div {
  width: 280px;
  margin: auto;
}

.Offer_box__3I1lw p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.Offer_box__3I1lw a {
  color: rgb(119, 157, 201);
  text-decoration: none;
}




.Offer_Offer__Fwt_G li {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.Offer_Offer__Fwt_G ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Offer_Picker__26dDz input {
  color: white;
}

.Buy_buy__2pAgg {
  padding-top: 1em;
  background-color: rgba(95, 158, 160, 0.034);
  height: 24em;
  border-radius: 0 0 2em 2em;
}

.Buy_greened__2LrYF {
  padding-top: 1em;
  color: #37a99e;
}


.Buy_action__J2kU1 {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 15em;
  cursor: pointer;
}

.Buy_action__J2kU1:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.Buy_action__J2kU1:focus {
  outline: none;
}


.Buy_action__J2kU1:disabled{
  color: #555555;
  border: #555555 solid 0.05em;
}


.Buy_network__22Dhd p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}


.Buy_buy__2pAgg li {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.Buy_buy__2pAgg ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}



.Buy_inputDiv__2vbI4 {
  padding: 3em 0 3em 0;
  height: 5em;
  align-items: center;
}

.Buy_input__10C4B {
  height: 3em;
  width: 28em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(23, 60, 139, 0.301);
}


.Buy_rampButton__2S3-Q {
  background-color: #21bf73;
  color: white;
  font-size: 1em;
  border: #21bf73 solid 0.05em;
  border-radius: 0.5em;
  height: 2.5em;
  width: 6em;
  margin-top: 1em;
  cursor: pointer;
}

.Buy_rampButton__2S3-Q:hover {
  background-color: #10af63;
}


.Buy_rampButton__2S3-Q:focus {
  outline: none;
 }

.Buy_rampButton__2S3-Q p {
   display: inline;
   position: relative;
 }


 .Buy_fcctButton__1Oa0Z {
  background-color: #404342;
  color: white;
  font-size: 1em;
  border: #404342 solid 0.05em;
  border-radius: 0.5em;
  height: 2.5em;
  width: 6em;
  margin-top: 1em;
  cursor: pointer;
}

.Buy_fcctButton__1Oa0Z:hover {
  background-color: #404342;
}


.Buy_fcctButton__1Oa0Z:focus {
  outline: none;
 }

.Buy_fcctButton__1Oa0Z p {
   display: inline;
   position: relative;
 }



.Dig_info__2QVAp {
  padding-top: 0.5em;
  background-color: rgba(160, 158, 95, 0.034);
  border-radius: 0 0 2em 2em;
}

.Dig_info__2QVAp p {
  padding-top: 1em;
  color: rgba(250,250,250, 0.7);
  font-size: 0.7em;
}

@media only screen and (max-device-width: 400px) {
  .Dig_info__2QVAp p {
    font-size: 0.4em;
  }
}


.Dig_info__2QVAp button {
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 20em;
  cursor: pointer;
}

.Dig_info__2QVAp button:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.Dig_info__2QVAp button:focus {
  outline: none;
}


.Dig_network__3Uvz- p {
  color: rgb(255, 255, 255);
  font-size: 0.8em;
}

.Dig_info__2QVAp li {
  color: rgb(255, 255, 255);
  font-size: 0.8em;
}

@media only screen and (max-device-width: 400px) {
  .Dig_info__2QVAp li {
    font-size: 0.4em;
  }
}


.Dig_info__2QVAp ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.Dig_special__3gij3 h1 {
  margin-top: 1.2em;
}
.Dig_icon__6nbiC {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.Dig_inputDiv__1zeYB {
  padding: 2em;
  height: 5em;
  align-items: center;
}

.Dig_input__8acSk {
  height: 3em;
  width: 20em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(139, 60, 23, 0.301);
}

.Dig_totals__1uBum {
  margin-top: 3em;
  height: 3em;
}

.Dig_goMax__2WwKb {
  cursor: pointer;
}

.Dig_goMax__2WwKb h5 {
  font-size: 2em;
}


.Dig_box__2O03B div {
  width: 280px;
  margin: auto;
}

.Dig_box__2O03B p {
  color: rgb(255, 255, 255);
  font-size: 0.8em;
}

.Dig_box__2O03B a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}



.Dig_boxHigh__1dHza div {
  width: 280px;
  margin: auto;
}

.Dig_boxHigh__1dHza p {
  color: white;
  font-size: 0.8em;
}


.Dig_digButton__3oN0R {
  background-color: rgba(200,2000,200,0.1);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 15em;
  margin-top: 1em;
  cursor: pointer;
}

.Dig_digButton__3oN0R:hover {
  background-color: rgba(200,2000,200,0.3);
}


.Dig_digButton__3oN0R:focus {
  outline: none;
 }


.Dig_digIcon__2IREN {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.Dig_digButton__3oN0R p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}



.Dig_allButton__RxTZ- {
  background-color: rgba(200,2000,200,0.1);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 3em;
  margin-top: 1em;
  cursor: pointer;
}

.Dig_allButton__RxTZ-:hover {
  background-color: rgba(200,2000,200,0.3);
}


.Dig_allButton__RxTZ-:focus {
  outline: none;
 }

.Dig_allButton__RxTZ- p {
   display: inline;
   position: relative;
 }

.Dig_larger__2fMDq {
  font-size: 1.2em;
}


.Start_info__2SGcc {
  padding-top: 0.5em;
  background-color: rgba(160, 158, 95, 0.034);
  border-radius: 0 0 2em 2em;
}

.Start_info__2SGcc p {
  padding-top: 1em;
  color: rgba(250,250,250, 0.7);
  font-size: 0.7em;
}

@media only screen and (max-device-width: 400px) {
  .Start_info__2SGcc p {
    font-size: 0.4em;
  }
}


.Start_info__2SGcc button {
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 20em;
  cursor: pointer;
}

.Start_info__2SGcc button:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.Start_info__2SGcc button:focus {
  outline: none;
}


.Start_network__1kcmK p {
  color: rgb(255, 255, 255);
  font-size: 0.8em;
}

.Start_info__2SGcc li {
  color: rgb(255, 255, 255);
  font-size: 0.8em;
}

@media only screen and (max-device-width: 400px) {
  .Start_info__2SGcc li {
    font-size: 0.4em;
  }
}


.Start_info__2SGcc ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.Start_special__39wfp h1 {
  margin-top: 1.2em;
}
.Start_icon__1_rar {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.Start_inputDiv__3vUFx {
  padding: 2em;
  height: 5em;
  align-items: center;
}

.Start_input__1dbYG {
  height: 3em;
  width: 20em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(139, 60, 23, 0.301);
}

.Start_totals__1hggw {
  margin-top: 3em;
  height: 3em;
}

.Start_goMax__3rl2G {
  cursor: pointer;
}

.Start_goMax__3rl2G h5 {
  font-size: 2em;
}


.Start_box__49oba div {
  width: 280px;
  margin: auto;
}

.Start_box__49oba p {
  color: rgb(255, 255, 255);
  font-size: 0.8em;
}

.Start_box__49oba a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}



.Start_boxHigh__3NIaw div {
  width: 280px;
  margin: auto;
}

.Start_boxHigh__3NIaw p {
  color: white;
  font-size: 0.8em;
}


.Start_StartButton__cBKEW {
  background-color: rgba(200,2000,200,0.1);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 10em;
  width: 12em;
  margin-top: 1em;
  cursor: pointer;
}

.Start_StartButton__cBKEW:hover {
  background-color: rgba(200,2000,200,0.3);
}


.Start_StartButton__cBKEW:focus {
  outline: none;
 }


.Start_StartIcon__JzwXg {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.Start_StartButton__cBKEW p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}



.Start_allButton__63iBy {
  background-color: rgba(200,2000,200,0.1);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 3em;
  margin-top: 1em;
  cursor: pointer;
}

.Start_allButton__63iBy:hover {
  background-color: rgba(200,2000,200,0.3);
}


.Start_allButton__63iBy:focus {
  outline: none;
 }

.Start_allButton__63iBy p {
   display: inline;
   position: relative;
 }

.Start_larger__1JyD8 {
  font-size: 1.2em;
}

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

xbody {
  margin: 0;
  background: linear-gradient(#122747, #010203);
  color: white;

  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
}

body {
  margin: 0;
/*  background: linear-gradient(#122747, #010203);*/
  color: white;

/*
  background-repeat: no-repeat;
  background-attachment: fixed; */
  height: 100%;

  background-image: url(/static/media/paperDamask.07ce90e4.jpg);
  background-size: 200px;

}

