
.info {
  padding-top: 0.5em;
  background-color: rgba(160, 158, 95, 0.034);
  border-radius: 0 0 2em 2em;
}

.info p {
  padding-top: 1em;
  color: rgba(250,250,250, 0.7);
  font-size: 0.7em;
}

@media only screen and (max-device-width: 400px) {
  .info p {
    font-size: 0.4em;
  }
}


.info button {
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 20em;
  cursor: pointer;
}

.info button:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.info button:focus {
  outline: none;
}


.network p {
  color: rgb(255, 255, 255);
  font-size: 0.8em;
}

.info li {
  color: rgb(255, 255, 255);
  font-size: 0.8em;
}

@media only screen and (max-device-width: 400px) {
  .info li {
    font-size: 0.4em;
  }
}


.info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.special h1 {
  margin-top: 1.2em;
}
.icon {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.inputDiv {
  padding: 2em;
  height: 5em;
  align-items: center;
}

.input {
  height: 3em;
  width: 20em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(139, 60, 23, 0.301);
}

.totals {
  margin-top: 3em;
  height: 3em;
}

.goMax {
  cursor: pointer;
}

.goMax h5 {
  font-size: 2em;
}


.box div {
  width: 280px;
  margin: auto;
}

.box p {
  color: rgb(255, 255, 255);
  font-size: 0.8em;
}

.box a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}



.boxHigh div {
  width: 280px;
  margin: auto;
}

.boxHigh p {
  color: white;
  font-size: 0.8em;
}


.StartButton {
  background-color: rgba(200,2000,200,0.1);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 10em;
  width: 12em;
  margin-top: 1em;
  cursor: pointer;
}

.StartButton:hover {
  background-color: rgba(200,2000,200,0.3);
}


.StartButton:focus {
  outline: none;
 }


.StartIcon {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.StartButton p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}



.allButton {
  background-color: rgba(200,2000,200,0.1);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 3em;
  margin-top: 1em;
  cursor: pointer;
}

.allButton:hover {
  background-color: rgba(200,2000,200,0.3);
}


.allButton:focus {
  outline: none;
 }

.allButton p {
   display: inline;
   position: relative;
 }

.larger {
  font-size: 1.2em;
}
