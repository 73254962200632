.profile {
  padding-top: 1em;
  background-color: rgba(95, 158, 160, 0.034);
  border-radius: 0 0 2em 2em;
}

.profile p {
  padding-top: 0.5em;
  color: #37a99e;
}

.profile button {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 15em;
  cursor: pointer;
}

.profile button:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.profile button:focus {
  outline: none;
}



.importButton {
  background-color: rgba(255, 255, 255, 0.0);
  color: white;
  font-size: 0.6em;
  border: rgb(253, 85, 36) solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 5em;
  margin-top: 0em;
  margin-left: 1em;
  cursor: pointer;
}

.importButton:hover {
  background-color: rgba(255, 255, 255, 0.0);
}


.importButton:focus {
  outline: none;
 }

.importButton p {
   display: inline;
   position: relative;
 }




.profile li {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.profile ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.network div {
  max-width: 520px;
  margin: auto;
}

.network p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.network a {
  color: white;
  text-decoration: none;
}


.box div {
  width: 350px;
  margin: auto;
}

@media only screen and (max-device-width: 400px) {
  .box div {
    width: 300px;
    margin: auto;
  }
}

.box p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.box a {
  color: white;
  text-decoration: none;
}




.box container {
    position: relative;
}

.box overlay {
     position: absolute;
     botton: 20px;
}



.preview {
    position: relative;
    padding: 1em;
}

.previewPrice {
  padding: 0.0em;
}

.previewItem {
   width: 150px;
}

@media only screen and (max-device-width: 400px) {
  .previewItem {
     width: 100px;
  }
}


.gridItem {
  padding: 1em;
}

.frostedGlassEffect {
  box-shadow: 0 0 1rem 0 rgba(33, 31, 31, 0.2);
  background-color: rgba(200, 200, 255, .08);
	backdrop-filter: blur(2px);
  border-radius: 10px;
  xborder: rgba(200,200,255,0.5) solid 1px;
  border: rgba(255,246,200,0.5) solid 1px;
  position: relative;
  overflow: hidden;
}

.tbl {
  
}
