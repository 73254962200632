

.tbl th:first-of-type {
  border-top-left-radius: 10px;
}
.tbl th:last-of-type {
  border-top-right-radius: 10px;
}

.tbl tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 10px;
}
.tbl tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 10px;
}

.tbl tr:first-of-type td:first-of-type {
  border-top-left-radius: 10px;
}
.tbl tr:first-of-type td:last-of-type {
  border-top-right-radius: 10px;
}

.tbl table {
    border-collapse:separate;
    border: rgba(255,246,200,0.5) solid 1px;
    xborder:solid rgba(200,200,255,0.5) 1px;
    width: 100%;
    border-radius:12px;
}
/*

.tbl td, th {
    border-left:solid rgba(200,200,255,0.5) 1px;
    border-top:solid rgba(200,200,255,0.5) 1px;
}

.tbl th {
    background-color: rgba(200,200,255,0.5) 1px;
    border-top: none;
}

.tbl td:first-child, th:first-child {
     border-left: none;
}

*/

.tbl td {
  color: rgb(119, 157, 201);
  ycolor: rgb(201 166 6);
  font-size: 0.8em;
  padding: 0.4em;
}

.tbl td:nth-child(2) {
  font-weight: 600;
  text-align: left;
}

.tbl td:nth-child(1) {
  text-align: right;
  background: rgba(119,157,201);
  xbackground: linear-gradient(to right, #FCF6BA, #AA771C);
  color: white;
  /*
  background-color:  rgb(119, 157, 201);
  color: rgba(200,200,255,0.5);
  */

}
