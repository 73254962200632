.outStore {
  padding-top: 1em;
  background-color: rgba(95, 158, 160, 0.034);
}

.outStore p {
  padding-top: 1em;
  color: #37a99e;
}

.outStore a {
  color: white;
}

.outStore button {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 10em;
  cursor: pointer;
}

.outStore button:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.outStore button:focus {
  outline: none;
}


.outStore
button:disabled,
button[disabled]{
  color: #555555;
  border: #555555 solid 0.05em;
}


.network p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}


.outStore li {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

.outStore ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}






.stakeIcon {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.inputDiv {
  padding: 3em 0 3em 0;
  height: 5em;
  align-items: center;
}

.input {
  height: 3em;
  width: 28em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(23, 60, 139, 0.301);
}



.stakeButton {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 20em;
  margin-top: 1em;
  cursor: pointer;
}


.stakeButton:hover {
  background-color: #2675cf;
}

.stakeButton:focus {
  outline: none;
 }

 .stakeButton p {
   display: inline;
   position: relative;
   bottom: 0.5em;
   color: white;
 }
